import React, { useState } from "react";
import { graphql, Link } from "gatsby";
import SEO from "../components/seo";
import { Row, Col, Button, Modal, Card, Container } from "react-bootstrap";
import Layout from "../components/Layout";
import PreviewCompatibleImage from "../components/PreviewCompatibleImage";
import ContactForm from "../components/forms/contact";
import adasProImage from "../../static/img/adasPro.webp";
import cutOutLineImage from "../../static/img/line-blue.png";
import glassLiftingImage from "../../static/img/glassPartLiftingDevice.png";

const ContactModal = ({ show, handleClose }) => (
  <Modal show={show} onHide={handleClose}>
    <Modal.Header closeButton>
      <Modal.Title>Contact me regarding the Auto Glass Week Special</Modal.Title>
    </Modal.Header>
    <div className="px-5 py-5">
      <ContactForm referrer="Auto Glass Week Special" />
    </div>
  </Modal>
);

export const IndexPageTemplate = ({ title, titleSEO, logo, content, specialImage }) => {
  const [show, setShow] = useState(false);
  return (
    <>
      <SEO title="Home" />
      <ContactModal show={show} handleClose={() => setShow(false)} />
      <Container>
        <Row className="d-flex flex-row justify-content-center align-items-center mx-0 mt-5">
          <Col className="text-center d-block d-lg-none mb-5">
            <div className="ribbon">AUTO GLASS WEEK SPECIAL! </div>
          </Col>
          <Col xs={12} lg={6} xl={{ span: 5, offset: 1 }} xxl={{ span: 4, offset: 2 }}>
            <PreviewCompatibleImage
              imageInfo={{
                image: specialImage,
                alt: "image",
                style: {
                  height: "94vh",
                  width: "auto",
                  maxWidth: "100%",
                  objectFit: "contain",
                  left: "50%",
                  transform: "translate(-50%, 0%)",
                },
              }}
            />
          </Col>
          <Col xs={12} lg={6} className="text-center text-lg-left pl-lg-5">
            <div className="ribbon d-none d-lg-inline-block">AUTO GLASS WEEK SPECIAL!</div>
            <h2 className="py-3 py-lg-0">
              Special package offer <br />
              Now through July 4th!
            </h2>
            <h4 className="mt-3 border-bottom-white mobile-only">
              <span>Package includes</span>
            </h4>
            <p>
              <ul className="mobile-centered">
                <li>X-431 ADAS MOBILE</li>
                <li>X-431 THROTTLE</li>
              </ul>
            </p>
            <h4 className="border-bottom-white mobile-only">
              <span>Receive a FREE</span>
            </h4>
            <p>
              <ul className="mobile-centered">
                <li>ROXIE (pre/post-scan Diagnostic Tool)</li>
              </ul>
            </p>
            <p></p>

            <Button onClick={() => setShow(!show)}>Contact Me</Button>
            <p className="pt-3">
              Or call: <a href="tel:2096020734">209-602-0734</a>
            </p>
          </Col>
          <Col>
            <hr style={{ width: "65vw" }} />
          </Col>
        </Row>
      </Container>
      <Row className="d-flex flex-row justify-content-center mt-5 mx-0">
        <Col xs={12} className="d-flex flex-column justify-content-center  text-center mb-5">
          <h2 className="block-invert mx-auto">Featured Products</h2>
        </Col>
        <Col
          xs={12}
          md={6}
          lg={4}
          xl={3}
          className="d-flex flex-column justify-content-center align-items-center text-center mb-5"
        >
          <h3 className="text-center mb-3">ADAS Calibration Equipment</h3>
          <Card className="card-gray" style={{ width: "18rem" }}>
            <Card.Body>
              <Card.Img variant="top" src={adasProImage} />
              <Card.Title>Calibration Equipment for Advanced Driver Assist Systems</Card.Title>
              <Link to="/adas" className="card-link">
                <Button variant="secondary">View Details →</Button>
              </Link>
            </Card.Body>
          </Card>
        </Col>
        <Col
          xs={12}
          md={6}
          lg={4}
          xl={3}
          className="d-flex flex-column justify-content-center align-items-center text-center mb-5"
        >
          <h3 className="text-center mb-3">Cut-Out Lines</h3>
          <Card className="card-gray" style={{ width: "18rem", height: "341px" }}>
            <Card.Body>
              <Card.Img variant="top" src={cutOutLineImage} style={{ height: "179px", width: "auto" }} />
              <Card.Title className=" mb-4 pt-3">Reusable wire designed to ease windshield removal</Card.Title>
              <Link to="/cut-out-lines" className="card-link">
                <Button variant="secondary">View Details →</Button>
              </Link>
            </Card.Body>
          </Card>
        </Col>
        <Col
          xs={12}
          md={6}
          lg={4}
          xl={3}
          className="d-flex flex-column justify-content-center align-items-center text-center mb-5"
        >
          <h3 className="text-center mb-3">Glass Lifting Device</h3>
          <Card className="card-gray" style={{ width: "18rem", height: "341px" }}>
            <Card.Body>
              <Card.Img variant="top" src={glassLiftingImage} style={{ height: "179px", width: "auto" }} />
              <Card.Title className="pt-2">
                Designed to assist technicians in the fit and removal of autoglass
              </Card.Title>
              <Link to="/glass-part-lifting-device" className="card-link">
                <Button variant="secondary">View Details →</Button>
              </Link>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;
  return (
    <Layout>
      <IndexPageTemplate
        title={frontmatter.title}
        titleSEO={frontmatter.titleSEO}
        logo={frontmatter.logo}
        content={frontmatter.content}
        specialImage={frontmatter.specialImage}
      />
    </Layout>
  );
};

export default IndexPage;

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        titleSEO
        logo {
          childImageSharp {
            fluid(maxWidth: 800, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        specialImage {
          childImageSharp {
            fluid(maxWidth: 1200, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        content
      }
    }
  }
`;
